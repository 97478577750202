import React, { useEffect } from "react";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import { compose, withProps, withHandlers } from "recompose";

import * as styleSheet from "./index.module.scss"

const Map = compose(
		withProps({
			googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" + process.env.GATSBY_GOOGLE_API_KEY,
			loadingElement: <div />,
			containerElement: <div />,
			mapElement: <div className={styleSheet.map} />,
		}),
		withHandlers(() => {
			const refs = {
				map: undefined,
			}

			return {
				onMapMounted: () => ref => {
					refs.map = ref;
				},
				setTheBounds: () => (newBounds) => {
					refs.map && refs.map.fitBounds(newBounds);
				}
			}
		}),
		withScriptjs,
		withGoogleMap
	)(props => {

		useEffect(() => {
			if(props.children && props.children.length){
				prepareAndSetBounds();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.children]);

		const prepareAndSetBounds = () => {
			let bounds = new window.google.maps.LatLngBounds();
			props.children.forEach((marker, i) => {
				bounds.extend(new window.google.maps.LatLng(marker.props.position.lat, marker.props.position.lng));
			});
			(props.children.length > 1) && props.setTheBounds(bounds);
		};

		return (
			<GoogleMap
				defaultZoom={8}
				forwardRef="map" 
				center={{ lat: props.coordinates.lat, lng: props.coordinates.lng }}
				defaultOptions={{disableDefaultUI: true}} 
				google={props.google}
				ref={props.onMapMounted} 
			>
				{props.children}
			</GoogleMap>
		)
	}
);

export default Map;